<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    //- .col-lg-12.mb-2.title-box
    //- b-button-group.tab-group
    //-   b-button(variant="primary") 編輯稿件
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/apmanagement") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group.mb-3
                  label 員工編號
                  br
                  input.form-control(
                    v-model="manager.account_id",
                    type="text",
                    disabled,
                    placeholder=""
                  )
              .col-lg-4
                .form-group.mb-3
                  label 姓名
                  br
                  input.form-control(
                    v-model="manager.name",
                    type="text",
                    placeholder=""
                  )
              .col-lg-4
                .form-group.mb-3
                  label email
                  br
                  input.form-control(
                    v-model="manager.email",
                    type="text",
                    disabled,
                    placeholder=""
                  )
              .col-lg-4
                .form-group.mb-3
                  label 部門
                  br
                  multiselect(
                    v-model="currentDepartment", 
                    :options="ManagerDepartmentOptions", 
                    placeholder="請選擇部門", 
                    track-by="id",
                    label="name",
                    select-label=""
                  )
              .col-lg-4
                .form-group.mb-3
                  label 職稱
                  br
                  multiselect(
                    v-model="currentPosition", 
                    :options="ManagerPositionOptions", 
                    placeholder="請選擇職稱", 
                    track-by="id",
                    label="name",
                    select-label=""
                  )
              .col-lg-4
                .form-group.mb-3
                  label 帳戶狀態
                  br
                  select.form-control(v-model="manager.status")
                    option(value="0") 停用
                    option(value="1") 啟用
                    option(value="2") 禁用                    
              .col-12.text-right 
                b-button.width-sm(variant="primary",@click="putManagerDetail") 儲存
             
      
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import SelectImage from "@/components/select-image";

/**
 * Starter component
 */
export default {
  name: "ManagerEdit",
  data () {
    return {
      title: "帳號編輯",
      items: [
        {
          text: "帳號/權限管理",
          href: "/apmanagement",
        },
        {
          text: "帳號編輯",
          active: true,
        }
      ],
      manager: {},
      managerId: '',
      currentDepartment: false,
      currentPosition: false,
      currentManagerStatus: false,
      ManagerDepartmentOptions: [],
      ManagerPositionOptions: [],
      ManagerStatusOptions: [
        { id: 0, label: '停用' },
        { id: 1, label: '啟用' },
        { id: 2, label: '禁用' }
      ]
    };
  },
  computed: {},
  mounted () { },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    SelectImage
  },
  watch: {
    currentDepartment () {
      this.getManagerPositionList()
    }
  },
  created () {
    this.managerId = this.$route.params.id
    this.getManagerDetail()
    this.getManagerDepartmentsList()
  },
  methods: {
    // 取得部門清單
    getManagerDepartmentsList () {
      let vm = this
      this.$root.apis.getManagerDepartmentsList(function (_response) {
        vm.ManagerDepartmentOptions = _response.body.data
      })
    },
    // 取得職位清單
    getManagerPositionList () {
      if (!this.currentDepartment.id) {
        return
      }

      let vm = this
      let departmentsId = this.currentDepartment.id
      this.$root.apis.getManagerDepartmentsPositionsList(departmentsId, function (_response) {
        vm.ManagerPositionOptions = _response.body.data
      })
    },
    // 取得管理員資料
    getManagerDetail () {
      let vm = this
      this.$root.apis.getManagerDetail(this.managerId, function (_response) {
        let manager = _response.body.data
        vm.currentDepartment = (manager.department.id) ? manager.department : false
        vm.currentPosition = (manager.position.id) ? manager.position : false
        vm.manager = manager
      })
    },
    // 更新管理員資料
    putManagerDetail () {
      let data = {
        user_name: this.manager.name,
        department_id: this.currentDepartment.id,
        position_id: this.currentPosition.id,
        status: this.manager.status
      }

      let vm = this
      this.$root.apis.putManagerDetail(this.managerId, data, function (_response) {
        vm.getManagerDetail()
        vm.$root.common.showSingleNotify("管理員資料設定成功！")
      })
    }
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px


</style>
